import React, { useContext } from 'react';
import Cookies from 'js-cookie';
import type { ReactNode } from 'react';
import {
    getGlobalECountryCookie,
    getSiteUrlSegment,
    getValidSiteCodeAndLocaleFromUrl,
    isGlobalECountry,
} from '@sezane/front-components';
import { useQuery } from '@tanstack/react-query';

import Loader from '@components/Loader/Loader';
import { getRoutes } from '@hooks/useUrl/utils';
import apiClient from '@utils/api';
import apiOms from '@utils/apiOms';
import { BRANDS, getFrontUrl } from '@utils/brand';
import type { OMS } from 'types';
import type { Site } from 'types/apiEcommerce';

const DEVISE_COOKIE_NAME = 'Sezane';
const DEFAULT_DEVISE = 1;

type AppContextType = {
    brandCode: BRANDS;
    frontUrl: string;
    isGlobalE?: boolean;
    locale: string;
    mappingCountrySite: Record<string, string>;
    referencesOms: OMS.References;
    site: Site;
    sites: Site[];
    siteSegment: string;
};

type InitialData = Pick<AppContextType, 'site' | 'sites' | 'mappingCountrySite' | 'locale'>;

type AppProviderProps = {
    brandCode: BRANDS;
    children?: ReactNode;
    initialData?: InitialData;
};

const AppContext = React.createContext<AppContextType>({
    brandCode: BRANDS.sezane,
    frontUrl: '',
    locale: '',
    mappingCountrySite: {},
    referencesOms: {},
    site: undefined as any,
    sites: [],
    siteSegment: '',
});

export const getSite = (sites?: Site[]) => {
    const deviseCookie = Cookies.get(DEVISE_COOKIE_NAME);

    let site: Site | undefined;

    if (sites) {
        // if sites loaded
        if (deviseCookie) {
            // if we have a cookie, get site value from there
            const devise = Number(deviseCookie.replace('SiteDevise=', ''));
            site = sites.find(s => s.legacyCurrencyCookie === (isNaN(devise) ? DEFAULT_DEVISE : devise));
        } else {
            // if we don't, guess the site value from the url
            site = sites.find(s => window.location.pathname.startsWith(`/${s.code}`));

            if (!site) {
                // if we still haven't found the site, we default to FR
                site = sites.find(s => s.code === 'fr');
            }
        }
    }

    return site;
};

const WILDCARD_SITE_SEGMENT = 'xx-xx';
const isWildcardSite = () => window.location.pathname.startsWith(`/${WILDCARD_SITE_SEGMENT}`);

const redirectFromWildcardToCookieSite = (mappingCountrySite?: Record<string, string>, sites?: Site[]) => {
    if (!sites || !mappingCountrySite) return;

    const globalECookie = getGlobalECountryCookie();
    const siteCode = mappingCountrySite[globalECookie.countryISO!];
    const locale = globalECookie.locale || sites.find(s => s.code === siteCode)?.defaultLocale?.code;

    if (siteCode && locale) {
        const routes = getRoutes(siteCode, locale);

        let newPath = '';
        const checkOrderPath = /\/order\/([a-z0-9-]*)/.exec(window.location.pathname);
        const checkReturnPath = /\/return\/([a-z0-9-]*)/.exec(window.location.pathname);

        if (checkOrderPath && checkOrderPath[1]) newPath = `${routes.orders}/detail/${checkOrderPath[1]}`;
        else if (checkReturnPath && checkReturnPath[1]) newPath = `${routes.returns}/detail/${checkReturnPath[1]}`;
        else if (window.location.pathname.includes('/returns')) newPath = routes.returns;

        window.location.replace(`/${siteCode}-${locale}${process.env.REACT_APP_BASE_PATH || ''}${newPath}`);
    }
};

export const AppContextProvider = ({ brandCode, children, initialData }: AppProviderProps) => {
    const { data: sitesData, isFetching: isFetchingSites } = useQuery(
        ['sites'],
        async () => {
            const response = await apiClient.getBrandWebsites(brandCode, undefined, { secure: false });
            return response.data;
        },
        {
            enabled: !initialData,
        }
    );

    const sites = initialData ? initialData.sites : sitesData?.sites;
    const site = initialData ? initialData.site : getSite(sites);
    const siteSegment = site ? getSiteUrlSegment(site, window.location.pathname) : '';
    const mappingCountrySite = initialData
        ? initialData.mappingCountrySite
        : ((sitesData?.mappingCountrySite || {}) as Record<string, string>);
    const isGlobalE =
        site?.code === 'eu' && sitesData?.globalECountries ? isGlobalECountry(sitesData?.globalECountries) : false;
    const locale = initialData ? initialData.locale : site && getValidSiteCodeAndLocaleFromUrl(site).localeCode;

    const { data: referencesOms, isFetching: isFetchingReferencesOms } = useQuery(
        ['referencesOms'],
        async () => {
            const response = await apiOms.omsPublic.getReferencesList({
                localeCode: locale,
            });
            return response.data;
        },
        {
            enabled: !initialData && !!locale,
        }
    );

    if (!initialData) {
        if (isFetchingSites || isFetchingReferencesOms) {
            return <Loader fullPage />;
        }
        if (!isFetchingSites && !isFetchingReferencesOms) {
            if (isWildcardSite()) {
                // Used for Apple Wallet (cf. https://sezane.atlassian.net/browse/OMS-4272)
                redirectFromWildcardToCookieSite(mappingCountrySite, sites);
                return null;
            }
            if (!site) {
                // Site not matched => redirect to root
                window.location.replace('/');
                return null;
            }
        }
    }

    return (
        <AppContext.Provider
            value={{
                brandCode,
                frontUrl: getFrontUrl(),
                isGlobalE,
                locale: locale || 'FR',
                mappingCountrySite,
                referencesOms: referencesOms || {},
                site: site || {},
                sites: sites || [],
                siteSegment,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    const siteContext = useContext(AppContext);
    return siteContext;
};
