import { FormattedMessage } from 'react-intl';

import CancelOrderButton from '@components/Button/CancelOrderButton';
import ReturnOrderButton from '@components/Button/ReturnOrderButton';
import Faq from '@components/Faq';
import { type TimelineStatuses } from '@utils/order';
import type { TrackingOptionProps } from '@utils/types/order';
import { ORDER_KEY } from './config';

const trackingOptions: Record<TimelineStatuses, ({ shippingModeType }: TrackingOptionProps) => React.ReactNode> = {
    validated: ({ isCancelable, uid }) => {
        return (
            <div className="flex flex-col text-base">
                <FormattedMessage id="orders.order.detail.tracking.decription.validated" />
                {isCancelable && <CancelOrderButton className="mt-12 w-full px-4" uid={uid} queryKey={ORDER_KEY} />}
            </div>
        );
    },
    in_preparation: () => (
        <div className="flex flex-col">
            <div>
                <FormattedMessage id="orders.order.detail.tracking.decription.in_preparation.pre" />
            </div>
            <div className="mt-6">
                <FormattedMessage id="orders.order.detail.tracking.decription.in_preparation" />
            </div>
            <Faq faqLinks={['orders', 'shipping_conditions']} />
        </div>
    ),
    shipped: () => (
        <div className="flex flex-col">
            <div>
                <FormattedMessage id="orders.order.detail.tracking.decription.shipped.pre" />
            </div>
            <div className="mt-6">
                <FormattedMessage id="orders.order.detail.tracking.decription.shipped" />
            </div>
            <Faq faqLinks={['returns', 'return_conditions']} />
        </div>
    ),
    delivered: ({ shippingModeType, isReturnable, uid }) => {
        return (
            <div className="flex flex-col">
                <div>
                    <FormattedMessage id="orders.order.detail.tracking.decription.delivered.pre" />
                </div>
                {shippingModeType && ['pick_up_point', 'store_delivery'].includes(shippingModeType) && (
                    <div className="mt-6">
                        <FormattedMessage id="orders.order.detail.tracking.decription.delivered" />
                    </div>
                )}
                {shippingModeType === 'home_delivery' && isReturnable && (
                    <ReturnOrderButton className="mt-12" color="secondary" orderId={uid} />
                )}
                <Faq faqLinks={['returns', 'return_conditions']} />
            </div>
        );
    },
    delivered_to_customer: ({ isReturnable, uid }) => {
        return <div className="flex flex-col">{isReturnable && <ReturnOrderButton orderId={uid} fullWidth />}</div>;
    },
};

export default trackingOptions;
