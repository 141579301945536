import { useHover } from 'react-aria';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import AddToCart from '@components/Button/AddToCart';
import Button from '@components/Button/Button';
import CreateAlert from '@components/Button/CreateAlert';
import IconWishlist from '@components/Icons/IconWishlist';
import { useAppContext } from '@context/AppContext';
import { useAnalytics } from '@hooks/useAnalytics';
import useCurrency from '@hooks/useCurrency';
import { useSelectedProduct } from '@hooks/useSelectedProduct';
import apiOms from '@utils/apiOms';
import { DEFAULT_CURRENCYCODE, DEFAULT_IMG_PLACEHOLDER, DEFAULT_SITECODE } from '@utils/common';
import { DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH } from '@utils/image';
import { cn } from '@utils/main';
import { Box } from '../../components/Box/Box';
import { WISHLIST_KEY } from './config';
import type { WishlistAndAlertItem } from '../../types/apiOms';

interface WishlistProductItemProps {
    className?: string;
    product: WishlistAndAlertItem;
    setProductAlert: (ean: WishlistAndAlertItem) => void;
    index: number;
}
const WishlistProductItem = ({ className, product, setProductAlert, index }: WishlistProductItemProps) => {
    const {
        brandCode,
        site: { code },
    } = useAppContext();
    const { hoverProps, isHovered } = useHover({});
    const queryClient = useQueryClient();
    const currency = useCurrency();
    const { onProductRemovedWishlist } = useAnalytics();
    const intl = useIntl();

    const { mutate: deleteItemMutate, isLoading: isLoadingDeleteItem } = useMutation(
        (ean: string) => apiOms.omsAuthenticated.deleteWishlistItemAuthenticated(ean),
        {
            onSettled: (data, error, ean) => {
                onProductRemovedWishlist(ean);
                queryClient.invalidateQueries({ queryKey: [WISHLIST_KEY] });
            },
        }
    );
    const { mutate: replaceItemMutate, isLoading: isLoadingReplaceItem } = useMutation(
        (ean: string) =>
            apiOms.omsAuthenticated.replaceWishlistItemAuthenticated(
                product.ean!,
                {
                    brandCode,
                    siteCode: code || DEFAULT_SITECODE,
                    currencyCode: currency?.code || DEFAULT_CURRENCYCODE,
                },
                { newEan: ean }
            ),
        {
            onSettled: () => {
                queryClient.invalidateQueries({ queryKey: [WISHLIST_KEY] });
            },
        }
    );

    const { selectedProductLink } = useSelectedProduct(product);
    const canBeAddedToCart = ['available', 'preorderable'].includes(product?.status?.label!);
    const hasCta = ['available', 'preorderable', 'in_other_basket', 'unavailable'].includes(product?.status?.label!);

    const productImage = (
        <img
            loading={index < 2 ? 'eager' : 'lazy'}
            width={DEFAULT_IMAGE_WIDTH}
            height={DEFAULT_IMAGE_HEIGHT}
            src={product.imageUrl || DEFAULT_IMG_PLACEHOLDER}
            className={cn('h-full bg-gray-200 object-cover', !canBeAddedToCart && 'opacity-50')}
            alt={product.name || ''}
        />
    );

    return (
        <Box grid border imageBox className={cn(className, 'grid-cols-12 pb-0')} isLoading={isLoadingDeleteItem}>
            <div className="col-span-4">
                {product.link && selectedProductLink ? (
                    <a href={selectedProductLink} target="_blank" rel="noreferrer">
                        {productImage}
                    </a>
                ) : (
                    productImage
                )}
            </div>

            <div className="col-span-7 flex flex-col py-5 pl-5 font-account-heading uppercase ">
                <h3 className="font-normal desktop:mr-5">
                    {product.link && selectedProductLink ? (
                        <a href={selectedProductLink} target="_blank" rel="noreferrer">
                            {product.name}
                        </a>
                    ) : (
                        product.name
                    )}
                </h3>
                <div className="mt-4 text-base text-gray-light">
                    {product.color}
                    {' — '}
                    <span className="text-gray-medium">{product.displayPrice}</span>
                </div>
                <div className="flex items-center text-gray-light mobile:mt-8 desktop:grow desktop:text-sm">
                    {product?.sizes &&
                        product?.sizes.map(size => (
                            <Button
                                key={size.ean}
                                color="tertiary"
                                className={cn('p-1', size.selected ? 'text-black underline' : 'font-normal')}
                                onClick={() => product?.ean && size.ean && replaceItemMutate(size.ean)}
                                disabled={isLoadingReplaceItem}
                            >
                                {size?.status?.label === 'available' ? size.size : <s>{size.size}</s>}
                            </Button>
                        ))}
                </div>
                {product?.ean && (
                    <div className={cn('mobile:mt-8', hasCta && 'pt-[45px]')}>
                        {product?.status?.label === 'available' && (
                            <AddToCart ean={product?.ean} className="p-5 text-sm" />
                        )}
                        {product?.status?.label === 'preorderable' && product?.status?.preorderDate && (
                            <AddToCart
                                ean={product.ean!}
                                className="p-5 text-sm"
                                label={
                                    <FormattedMessage
                                        id="alerts.preorder"
                                        values={{
                                            date: intl.formatDate(new Date(product.status.preorderDate), {
                                                dateStyle: 'short',
                                            }),
                                        }}
                                    />
                                }
                            />
                        )}
                        {product?.status?.label === 'in_other_basket' && (
                            <Button type="button" color="alert" className="p-5 px-10 text-sm">
                                <FormattedMessage id="alerts.in_other_basket" />
                            </Button>
                        )}
                        {product?.status?.label === 'unavailable' && (
                            <CreateAlert ean={product?.ean} className="p-5 text-sm" setProductAlert={setProductAlert} />
                        )}
                    </div>
                )}
            </div>

            <div className="col-span-1 py-5 pr-5 text-right">
                <Button
                    color="tertiary"
                    className="p-0 text-black"
                    isLoading={isLoadingDeleteItem || isLoadingReplaceItem}
                    onClick={() => product?.ean && deleteItemMutate(product.ean)}
                    {...hoverProps}
                >
                    <IconWishlist className="w-8" {...(!isHovered && { filled: true })} />
                </Button>
            </div>
        </Box>
    );
};

export default WishlistProductItem;
