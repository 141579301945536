import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

import type { OMS } from 'types';

interface OrderCustomisationProps {
    className?: string;
    product: OMS.OrderProduct;
}

const OrderCustomisation = ({ className, product: { customisation } }: OrderCustomisationProps) => {
    return !!customisation?.rows?.length ? (
        <span className={clsx('flex flex-wrap items-center text-gray-medium', className)}>
            <span>
                <FormattedMessage id="orders.order.detail.body.customisation" /> <i>{customisation.rows?.join(' ')}</i>
            </span>
            {customisation.color && customisation.rgbColor && (
                <>
                    <span className="mx-2">{' / '}</span>
                    <span>
                        <FormattedMessage id="orders.order.detail.body.customisation_color" />{' '}
                    </span>
                    <div
                        className="ml-2 inline-block h-6 w-6 rounded-full"
                        style={{ backgroundColor: customisation.rgbColor }}
                        title={customisation.color}
                    />
                </>
            )}
        </span>
    ) : null;
};

export default OrderCustomisation;
