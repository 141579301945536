import clsx from 'clsx';
import { useHover } from 'react-aria';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Box } from '@components/Box/Box';
import AddToCart from '@components/Button/AddToCart';
import Button from '@components/Button/Button';
import { useAppContext } from '@context/AppContext';
import { useSelectedProduct } from '@hooks/useSelectedProduct';
import apiOms from '@utils/apiOms';
import { DEFAULT_CURRENCYCODE, DEFAULT_IMG_PLACEHOLDER, DEFAULT_SITECODE } from '@utils/common';
import { DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH } from '@utils/image';
import { cn } from '@utils/main';
import { ALERTS_KEY } from './config';
import type { WishlistAndAlertItem } from 'types/apiOms';

interface MutationProps {
    ean: string;
}

interface AlertListItemProps {
    className?: string;
    alert: WishlistAndAlertItem;
    index: number;
}

const AlertListItem = ({ className, alert, index }: AlertListItemProps) => {
    const { brandCode, site } = useAppContext();
    const { hoverProps } = useHover({});
    const intl = useIntl();

    const queryClient = useQueryClient();

    const { mutate: deleteItemMutate, isLoading: isLoadingDeleteItem } = useMutation(
        ({ ean }: MutationProps) => apiOms.omsAuthenticated.deleteAlertAuthenticated(ean),
        {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: [ALERTS_KEY] });
            },
        }
    );
    const { mutate: replaceItemMutate, isLoading: isLoadingReplaceItem } = useMutation(
        (ean: string) =>
            apiOms.omsAuthenticated.replaceAlertAuthenticated(
                alert.ean!,
                {
                    brandCode,
                    siteCode: site.code || DEFAULT_SITECODE,
                    currencyCode: site?.defaultCurrency?.code || DEFAULT_CURRENCYCODE,
                    locale: site.defaultLocale?.code || DEFAULT_SITECODE,
                },
                { newEan: ean }
            ),
        {
            onSettled: () => {
                queryClient.invalidateQueries({ queryKey: [ALERTS_KEY] });
            },
        }
    );

    const { selectedProductLink } = useSelectedProduct(alert);
    const canBeAddedToCart = ['available', 'preorderable'].includes(alert?.status?.label!);

    const productImage = (
        <img
            loading={index < 2 ? 'eager' : 'lazy'}
            width={DEFAULT_IMAGE_WIDTH}
            height={DEFAULT_IMAGE_HEIGHT}
            src={alert?.imageUrl || DEFAULT_IMG_PLACEHOLDER}
            className={clsx('h-full w-full bg-gray-200 object-cover', !canBeAddedToCart && 'opacity-50')}
            alt={alert?.name ?? ''}
        />
    );

    return alert?.ean ? (
        <Box border={false} className={className} imageBox isLoading={isLoadingDeleteItem}>
            <div {...hoverProps} className="group relative">
                {alert.link && selectedProductLink ? (
                    <a href={selectedProductLink} target="_blank" rel="noreferrer">
                        {productImage}
                    </a>
                ) : (
                    productImage
                )}
                <div
                    className={clsx(
                        canBeAddedToCart ? 'hover:bg-white/40' : 'hover:bg-black/40',
                        'absolute left-0 top-0 h-full w-full bg-transparent transition-all duration-500 ease-in-out group-hover:visible'
                    )}
                >
                    <div className="flex h-full w-full flex-col justify-center ">
                        <Button
                            floating
                            color="tertiary"
                            isLoading={isLoadingDeleteItem || isLoadingReplaceItem}
                            onClick={() =>
                                alert.ean &&
                                deleteItemMutate(
                                    { ean: alert.ean },
                                    {
                                        onSettled: () => {
                                            queryClient.invalidateQueries([ALERTS_KEY]);
                                        },
                                    }
                                )
                            }
                            className="absolute right-0 top-0 px-5 text-3xl leading-8 !no-underline"
                        >
                            ✕
                        </Button>

                        <div className="flex justify-center mobile:hidden">
                            {alert?.status?.label === 'available' && <AddToCart className="w-auto" ean={alert.ean} />}
                            {alert?.status?.label === 'preorderable' && alert.status.preorderDate && (
                                <AddToCart
                                    className="w-auto"
                                    ean={alert.ean}
                                    label={
                                        <FormattedMessage
                                            id="alerts.preorder"
                                            values={{
                                                date: intl.formatDate(new Date(alert.status.preorderDate), {
                                                    dateStyle: 'short',
                                                }),
                                            }}
                                        />
                                    }
                                />
                            )}
                            {['in_other_basket', 'unavailable'].includes(alert?.status?.label!) && (
                                <span className="border border-white px-12 py-5 text-center font-account-heading font-button-font-weight uppercase text-white">
                                    {alert?.status?.label === 'in_other_basket' && (
                                        <FormattedMessage id="alerts.in_other_basket" />
                                    )}
                                    {alert?.status?.label === 'unavailable' && (
                                        <FormattedMessage id="alerts.unavailable" />
                                    )}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="mb-2 mt-8 text-center font-account-subheading font-medium uppercase">
                {alert.link && selectedProductLink ? (
                    <a href={selectedProductLink} target="_blank" rel="noreferrer">
                        {alert?.name}
                    </a>
                ) : (
                    alert?.name
                )}
                {alert?.displayPrice && <span className="text-gray-medium"> — {alert?.displayPrice}</span>}
            </h3>
            <span className="mb-5 text-center font-account-heading text-sm text-gray-light desktop:text-sm">
                {alert?.sizes &&
                    alert.sizes.map(size => (
                        <Button
                            key={size.ean}
                            color="tertiary"
                            className={cn(size.size === alert.size ? 'text-black underline' : 'font-normal', 'p-1')}
                            disabled={isLoadingReplaceItem || size.size === alert?.size}
                            onClick={() => alert?.ean && size.ean && replaceItemMutate(size.ean)}
                        >
                            {size.available ? size.size : <s>{size.size}</s>}
                        </Button>
                    ))}
            </span>

            <div className="mt-5 flex w-full self-center desktop:hidden">
                {alert?.status?.label === 'available' && (
                    <AddToCart ean={alert.ean} className="px-0! w-full self-center text-sm" />
                )}
                {alert?.status?.label === 'preorderable' && alert.status.preorderDate && (
                    <AddToCart
                        ean={alert.ean}
                        className="px-0! w-full self-center text-sm"
                        label={
                            <FormattedMessage
                                id="alerts.preorder"
                                values={{
                                    date: intl.formatDate(new Date(alert.status.preorderDate), {
                                        dateStyle: 'short',
                                    }),
                                }}
                            />
                        }
                    />
                )}
                {['in_other_basket', 'unavailable'].includes(alert?.status?.label!) && (
                    <div className="grow border border-gray-lighter py-5 text-center font-account-heading text-sm font-medium uppercase text-black">
                        {alert?.status?.label === 'in_other_basket' && <FormattedMessage id="alerts.in_other_basket" />}
                        {alert?.status?.label === 'unavailable' && <FormattedMessage id="alerts.unavailable" />}
                    </div>
                )}
            </div>
        </Box>
    ) : null;
};

export default AlertListItem;
