import { defineMessages } from 'react-intl';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';

import Loader from '@components/Loader/Loader';
import apiOms from '@utils/apiOms';
import { getCustomerReturnTrackingStatus } from '@utils/order';
import ReturnDetailsTracking from './ReturnDetailsTracking';
import ReturnDetailsValidated from './ReturnDetailsValidated';

const RETURN_KEY = 'returns';
const getOrderReturnDetail = (uuid: string) => ({
    queryKey: [RETURN_KEY, `${uuid}`],
    queryFn: () => apiOms.omsAuthenticated.getOrderReturnAuthenticated(uuid),
    keepPreviousData: true,
});

export const returnModesMessages = defineMessages({
    dhl_express: { id: 'orderReturn.return.type.dhl_express' },
    free: { id: 'orderReturn.return.type.free' },
    return_label: { id: 'orderReturn.return.type.return_label' },
    schneider: { id: 'orderReturn.return.type.schneider' },
    special: { id: 'orderReturn.return.type.special' },
    store_return: { id: 'orderReturn.return.type.store_return' },
});

const ReturnDetails = () => {
    const { returnUid = 'testId' } = useParams();

    const { data, isLoading, isSuccess } = useQuery(getOrderReturnDetail(returnUid));
    const orderReturn = data?.data;

    const currentCustomerTrackingStatus = getCustomerReturnTrackingStatus(orderReturn?.status) || 'canceled';

    return isLoading ? (
        <Loader />
    ) : isSuccess && orderReturn ? (
        currentCustomerTrackingStatus === 'validated' ? (
            <ReturnDetailsValidated orderReturn={orderReturn} currentTrackingStatus={currentCustomerTrackingStatus} />
        ) : (
            <ReturnDetailsTracking orderReturn={orderReturn} currentTrackingStatus={currentCustomerTrackingStatus} />
        )
    ) : null;
};

export default ReturnDetails;
