import { useEffect } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import type { CountryState } from '@sezane/front-components';
import {
    Dialog,
    type DialogProps,
    getCountryCodeFromConcatenatedWithCountryState,
    getInitialCountryState,
    UpdateAddress,
    yupAddressValidation,
} from '@sezane/front-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import Button from '@components/Button/Button';
import { useAuth } from '@context/AuthContext';
import { useCountry } from '@context/CountryContext';
import useConfirm from '@hooks/useConfirm';
import { ADDRESSES_KEY } from '@pages/InfoPage/config';
import { formatAddressForOMSApi } from '@utils/address';
import api from '@utils/apiOms';
import type { Rfc7807, UserAddress } from 'types/apiOms';

const confirmMessages = defineMessages({
    create: { id: 'informations.shipping.form.create.confirm' },
    update: { id: 'informations.shipping.form.update.confirm' },
    charactersRemaining: { id: 'validation.characters_remaining' },
});

interface AddressFormDialogProps extends Pick<DialogProps, 'open'> {
    address?: UserAddress;
    addresses?: UserAddress[];
    handleCloseDialog: () => void;
}

const schema = yup.object().shape({
    address: yup.object().when(['manualAddress'], {
        is: true,
        then: () => yupAddressValidation({ manualAddress: true, isShippingAddress: true }),
        otherwise: () => yupAddressValidation({ manualAddress: false, isShippingAddress: true }),
    }),
});

const AddressFormDialog = ({ open, handleCloseDialog, address, addresses }: AddressFormDialogProps) => {
    const { country } = useCountry();
    const { user, keycloakId } = useAuth();
    const intl = useIntl();
    const isUpdateAddress = !!address?.uid;

    const defaultValues = {
        ...address,
        address: {
            ...address?.address,
            id: address?.id ?? 0,
            firstName: address?.address?.firstName ?? user?.firstName,
            lastName: address?.address?.lastName ?? user?.lastName,
            country: address?.address?.country ?? country.label,
            countryCode: address?.address?.countryCode ?? country.countryISO,
            company: address?.address?.company ?? '',
            phone: address?.address?.phone ?? (user?.phone || ''),
            postcode: address?.address?.postcode ?? '',
            state: address?.address?.state ?? null,
            city: address?.address?.city ?? '',
            address: address?.address?.address ?? '',
            addressMore: address?.address?.addressMore ?? '',
            name: address?.address?.name ?? '',
            // Set manual address on starting address edition
            inputType: isUpdateAddress ? 'Manual' : null,
            streetNumber: '',
        },
        // Set manual address on starting address edition
        manualAddress: isUpdateAddress,
        countryState:
            address?.address?.countryCode && address?.address?.state
                ? getInitialCountryState(address?.address?.countryCode, address?.address?.state)
                : null,
    } as UserAddress & { manualAddress?: boolean };

    const methods = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });
    const queryClient = useQueryClient();
    const queryParams = {
        onSettled: () => {
            queryClient.invalidateQueries([ADDRESSES_KEY]);
        },
        onSuccess: () => {
            methods.reset({}, { keepValues: true });
            handleCloseDialog();
        },
        onError: ({ error }: { error: Rfc7807 }) => {
            error?.['invalid-params']?.forEach(({ name, reason }) =>
                methods.setError(name as keyof UserAddress, { message: reason })
            );
        },
    };

    const { mutateAsync: updateAddress, isLoading: isLoadingUpdate } = useMutation(
        (data: UserAddress) => api.omsAuthenticated.replaceAddress(keycloakId, data.uid!, data),
        queryParams
    );
    const { mutateAsync: addAddress, isLoading: isLoadingAdd } = useMutation(
        (data: UserAddress) => api.omsAuthenticated.addAddress(keycloakId, data),
        queryParams
    );

    // To remove after removing Country State concatenation in Components
    const formatAddressWithCountryState = ({
        countryState: _,
        ...formAddress
    }: UserAddress & { countryState: CountryState }) => {
        const countryState = formAddress.address?.countryCode?.split('-')[1];
        return {
            ...formAddress,
            address: {
                ...formAddress.address,
                countryCode: formAddress.address?.countryCode?.split('-')[0],
                state: formAddress.address?.state ?? countryState ?? null,
            },
        };
    };

    const onSubmit: SubmitHandler<UserAddress> = data => {
        if (isUpdateAddress) {
            // @ts-expect-error
            updateAddress(formatAddressWithCountryState({ ...data, address: formatAddressForOMSApi(data.address) }));
        } else {
            // @ts-expect-error
            addAddress(formatAddressWithCountryState({ ...data, address: formatAddressForOMSApi(data.address) }));
        }
    };

    const confirmCancel = useConfirm(
        intl.formatMessage(isUpdateAddress ? confirmMessages.update : confirmMessages.create),
        () => {
            methods.reset({ ...defaultValues }, { keepErrors: false });
            handleCloseDialog();
        }
    );

    // Set manual address on starting address edition
    useEffect(() => {
        isUpdateAddress && methods.setValue('manualAddress', true);
    }, [isUpdateAddress, methods]);

    const selectedCountry = methods.watch('address.country');
    const selectedCountryCode = methods.watch('address.countryCode');

    return (
        <Dialog open={open} onClose={confirmCancel} panelClassName="max-w-[65rem] p-12">
            <h2 className="mb-7 uppercase">
                {isUpdateAddress ? (
                    <FormattedMessage id="addresses.form.update.title" />
                ) : (
                    <FormattedMessage id="addresses.form.create.title" />
                )}
            </h2>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <UpdateAddress
                        addresses={addresses}
                        country={
                            selectedCountry && selectedCountryCode
                                ? {
                                      label: selectedCountry,
                                      countryISO: getCountryCodeFromConcatenatedWithCountryState(selectedCountryCode),
                                  }
                                : country
                        }
                        canEditCountry
                    />
                    <div className="desktop:flex desktop:flex-row-reverse desktop:justify-center">
                        <Button
                            className="mt-7 min-w-[18rem] mobile:w-full"
                            type="submit"
                            disabled={!methods.formState.isDirty}
                            isLoading={isLoadingUpdate || isLoadingAdd}
                        >
                            <FormattedMessage id="informations.shipping.form.submit" />
                        </Button>
                        <Button
                            className="mr-12 mt-7 min-w-[18rem] mobile:w-full"
                            color="secondary"
                            onClick={confirmCancel}
                        >
                            <FormattedMessage id="informations.shipping.form.cancel" />
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default AddressFormDialog;
