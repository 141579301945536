import { FormattedMessage } from 'react-intl';

import ReturnOrderButton from '@components/Button/ReturnOrderButton';
import { PageHeader } from '@components/commons';
import OrdersTabs from '@components/Tabs/OrdersTabs';
import { ReturnsProvider } from '@context/ReturnsContext';
import ReturnsList from './ReturnsList';

const ReturnsPage = () => {
    return (
        <>
            <OrdersTabs />
            <PageHeader title="orderReturns.title">
                <FormattedMessage id="orderReturns.title" />
            </PageHeader>
            <p className="mt-8 py-4 text-center">
                <ReturnOrderButton />
            </p>
            <ReturnsProvider>
                <ReturnsList />
            </ReturnsProvider>
        </>
    );
};

export default ReturnsPage;
