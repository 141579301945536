import { cn } from '@utils/main';
import './loader.css';

export interface LoaderProps {
    className?: string;
    color?: string;
    fullPage?: boolean;
    fullScreen?: boolean;
    large?: boolean;
    shadow?: boolean;
    size?: number;
    small?: boolean;
    zIndex?: string | number;
    position?: React.CSSProperties['position'];
}

const Loader = ({
    className,
    fullPage,
    fullScreen,
    large,
    shadow,
    color,
    size,
    small,
    zIndex = 'auto',
}: LoaderProps) => (
    <div
        className={cn('loader', className, {
            'loader--fullpage': fullPage,
            'loader--fullscreen': fullScreen,
            'loader--large': large,
            'loader--shadow': shadow,
            'loader--small': small,
        })}
    >
        <div className="loader__spinner" style={{ borderTopColor: color, height: size, width: size, zIndex }} />
    </div>
);

export default Loader;
