import { useState } from 'react';
import parsePhoneNumber from 'libphonenumber-js';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatPostCodeAndCity } from '@sezane/front-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Box } from '@components/Box/Box';
import Button from '@components/Button/Button';
import DeleteButton from '@components/Button/DeleteButton';
import IconEdit from '@components/Icons/IconEdit';
import IconStar from '@components/Icons/IconStar';
import { useAuth } from '@context/AuthContext';
import { ADDRESSES_KEY } from '@pages/InfoPage/config';
import apiOms from '@utils/apiOms';
import AddressFormDialog from './AddressFormDialog';
import type { UserAddress } from 'types/apiOms';

interface addressBlockProps {
    userAddress: UserAddress;
    addresses: UserAddress[];
}

export const AddressSummary = ({ userAddress, addresses }: addressBlockProps) => {
    const intl = useIntl();
    const { keycloakId } = useAuth();
    const queryClient = useQueryClient();

    const [open, setOpen] = useState(false);

    const { mutate: deleteAddressMutate, isLoading: isLoadingDeleteAddress } = useMutation(
        () => apiOms.omsAuthenticated.deleteAddress(keycloakId, userAddress.uid!),
        {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: [ADDRESSES_KEY] });
            },
        }
    );

    const handleClick = () => setOpen(true);
    const handleCloseDialog = () => setOpen(false);
    const formattedPhoneNumber = userAddress?.address?.phone
        ? parsePhoneNumber(userAddress?.address?.phone)?.formatInternational()
        : undefined;

    return (
        <>
            <Box className="flex-col justify-between border font-account-body mobile:p-4">
                <span className="mb-4 font-account-heading font-medium uppercase">
                    <span className="flex h-11 items-center">
                        {userAddress?.address?.name}
                        {userAddress?.isDefault && (
                            <IconStar
                                role="img"
                                fillRule="nonzero"
                                aria-label={intl.formatMessage({
                                    id: 'information.shipping.address.default_address',
                                })}
                            />
                        )}
                    </span>
                    {userAddress?.isDefault ? <></> : null}
                </span>
                <span className="text-lg leading-10">
                    {userAddress?.address?.firstName} {userAddress?.address?.lastName}
                </span>
                <span className="text-lg leading-10">{formattedPhoneNumber ?? null}</span>
                <span className="text-lg leading-10">{userAddress?.address?.address}</span>
                {userAddress?.address?.addressMore && (
                    <span className="text-lg leading-10">{userAddress?.address?.addressMore}</span>
                )}
                <span className="text-lg leading-10">
                    {formatPostCodeAndCity({
                        countryCode: userAddress?.address?.countryCode!,
                        city: userAddress?.address?.city!,
                        postcode: userAddress?.address?.postcode!,
                    })}
                </span>
                <span className="pb-7 text-lg leading-10">{userAddress?.address?.country}</span>
                <div className="flex desktop:flex-row">
                    <Button
                        color="tertiary"
                        className="group flex items-center justify-center gap-5 p-4 hover:text-black"
                        onClick={handleClick}
                    >
                        <FormattedMessage id="addresses.cta.edit" />
                        <IconEdit className="-translate-y-1 fill-gray-light transition-colors duration-300 group-hover:fill-black" />
                    </Button>
                    <DeleteButton onClick={() => deleteAddressMutate()} isLoading={isLoadingDeleteAddress}>
                        <FormattedMessage id="addresses.cta.delete" />
                    </DeleteButton>
                </div>
            </Box>
            {open && (
                <AddressFormDialog
                    open={open}
                    handleCloseDialog={handleCloseDialog}
                    address={userAddress}
                    addresses={addresses}
                />
            )}
        </>
    );
};
