import { useEffect, useState } from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Dropdown } from '@sezane/front-components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import AddressFormDialog from '@components/Address/AddressFormDialog';
import { AddressSummary } from '@components/Address/AddressSummary';
import Button from '@components/Button/Button';
import { SecondTitle, SubTitle } from '@components/commons';
import LoaderWrapper from '@components/Loader/LoaderWrapper';
import { useAppContext } from '@context/AppContext';
import { useAuth } from '@context/AuthContext';
import api from '@utils/apiOms';
import { ADDRESSES_KEY } from './config';
import type { UserAddress } from 'types/apiOms';

defineMessages({
    shippingAddress: { id: 'shipping.address' },
    shippingAddressDisableAutocomplete: { id: 'shipping.address.disable_autocomplete' },
    shippingAddressEdit: { id: 'shipping.address.edit' },
    shippingAddressAutocomplete: { id: 'shipping.address.enable_autocomplete' },
    shippingAddressAdditional: { id: 'shipping.address.fields.additional' },
    shippingAddressFieldsAddress: { id: 'shipping.address.fields.address' },
    shippingAddressFieldsAddressName: { id: 'shipping.address.fields.address_name' },
    shippingAddressFieldsCity: { id: 'shipping.address.fields.city' },
    shippingAddressFieldsCompany: { id: 'shipping.address.fields.company' },
    shippingAddressFieldsFirstname: { id: 'shipping.address.fields.firstname' },
    shippingAddressFieldsLastname: { id: 'shipping.address.fields.lastname' },
    shippingAddressFieldsPhone: { id: 'shipping.address.fields.phone_number' },
    shippingAddressFieldsPostalCode: { id: 'shipping.address.fields.postal_code' },
    shippingAddressFieldsStreetNumber: { id: 'shipping.address.fields.street_number' },
    shippingAddressFieldsNew: { id: 'shipping.address.new' },
    shippingAddressFieldsSelection: { id: 'shipping.address.selection' },
    shippingAddressFieldsStateSelection: { id: 'shipping.address.state_selection' },
    shippingAddressFieldsSuggestedCountry: { id: 'shipping.address.warning.suggested_country' },
    validationAddressPoBox: { id: 'validation.address.address.address.po_box' },
    validationAddressFirstNameRequired: { id: 'validation.address.address.firstName.required' },
    validationAddressLastNameRequired: { id: 'validation.address.address.lastName.required' },
    validationAddressLatincharacters: { id: 'validation.address.address.latin_characters' },
    validationAddressNameRequired: { id: 'validation.address.address.name.required' },
    validationAddressRequired: { id: 'validation.address.address.required' },
    validationAddressCityInvalid: { id: 'validation.address.city.invalid' },
    validationAddressCityRequired: { id: 'validation.address.city.required' },
    validationAddressPhoneNumberInvalid: { id: 'validation.address.phone.invalid' },
    validationAddressPhoneNumberRequired: { id: 'validation.address.phone.required' },
    validationAddressInvalidShipping: { id: 'validation.address.postcode.invalid_shipping' },
    validationAddressPostcodeRequired: { id: 'validation.address.postcode.required' },
    validationWarningDifferentState: { id: 'shipping.warning.different_state' },
    validationStreetNumber: { id: 'address.invalid_street_number' },
});

const MyAddresses = () => {
    const { keycloakId } = useAuth();
    const { brandCode, site } = useAppContext();
    const queryClient = useQueryClient();
    const intl = useIntl();

    const [openCreateAddressDialog, setOpenCreateAddressDialog] = useState(false);

    const {
        data: userAddressesResponse,
        isLoading: isLoadingUserAddresses,
        isSuccess: isSuccessUserAddresses,
    } = useQuery([ADDRESSES_KEY], () => {
        const query: { brandCode: string; siteCode: string; countryCode?: string } = {
            siteCode: site.code!,
            brandCode,
        };

        return api.omsAuthenticated.getAddresses(keycloakId, query);
    });
    const addresses = userAddressesResponse?.data || [];
    const currentDefaultShippingAddress = addresses.find(addr => addr?.isDefault);

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        reValidateMode: 'onChange',
        defaultValues: { defaultShippingAddress: currentDefaultShippingAddress },
    });
    const defaultShippingAddress = watch('defaultShippingAddress');

    // Reset defaulvalues after fetch, need to upgrade react hook form to remove theses lines
    useEffect(() => {
        setValue('defaultShippingAddress', currentDefaultShippingAddress);
    }, [currentDefaultShippingAddress, setValue]);

    const { mutate: mutateUpdate } = useMutation((data: UserAddress) =>
        api.omsAuthenticated.replaceAddress(keycloakId, data.uid!, data)
    );

    const onSubmitDefaultAddress: SubmitHandler<{ defaultShippingAddress?: UserAddress }> = ({
        defaultShippingAddress,
    }) => {
        currentDefaultShippingAddress && mutateUpdate({ ...currentDefaultShippingAddress, isDefault: false });
        defaultShippingAddress &&
            mutateUpdate(defaultShippingAddress, {
                onSettled: () => queryClient.invalidateQueries([ADDRESSES_KEY]),
                onSuccess: () => reset({}, { keepValues: true }),
            });
    };

    const onChangeDefaultShippingAddress = (addr: UserAddress) => {
        setValue('defaultShippingAddress', { ...addr, isDefault: true }, { shouldDirty: true });
        handleSubmit(onSubmitDefaultAddress)();
    };

    const handleCloseDialog = () => setOpenCreateAddressDialog(false);

    return (
        <div className="border-t border-gray-lighter pt-10">
            <SecondTitle description={<FormattedMessage id="informations.shipping.description" />}>
                <FormattedMessage id="informations.shipping.title" />
            </SecondTitle>

            {!!addresses.length && (
                <>
                    <SubTitle className="text-base">
                        <FormattedMessage id="informations.shipping.default_address.title" />
                    </SubTitle>
                    <form>
                        <div className="grid grid-cols-1 gap-7 desktop:grid-cols-2 large:grid-cols-3">
                            <div className="col-span-2">
                                {/* Default shipping address Dropdown */}
                                <Controller
                                    control={control}
                                    name="defaultShippingAddress"
                                    render={() => (
                                        <Dropdown
                                            id="address-dropdown"
                                            className="overflow-hidden"
                                            items={addresses}
                                            onChange={onChangeDefaultShippingAddress}
                                            fontStyles="font-account-body"
                                            renderItem={addressItem => (
                                                <span className="w-full">
                                                    <p className="w-full truncate pr-6 text-lg">
                                                        {addressItem?.address?.name} - {addressItem?.address?.firstName}{' '}
                                                        {addressItem?.address?.lastName} {addressItem?.address?.address}
                                                    </p>
                                                </span>
                                            )}
                                            value={defaultShippingAddress}
                                            defaultLabel={intl.formatMessage({
                                                id: 'informations.shipping.default_address.label',
                                            })}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </form>
                    <SubTitle className="text-base">
                        <FormattedMessage id="informations.shipping.saved_addresses" />
                    </SubTitle>
                </>
            )}

            <LoaderWrapper isLoading={isLoadingUserAddresses}>
                <div className="grid grid-cols-1 gap-7 desktop:grid-cols-2 large:grid-cols-3">
                    {isSuccessUserAddresses &&
                        addresses.map(address => (
                            <AddressSummary userAddress={address} addresses={addresses} key={address.id} />
                        ))}
                </div>
                <Button
                    className="mb-8 mt-6 mobile:w-full desktop:w-[28rem]"
                    onClick={() => setOpenCreateAddressDialog(true)}
                >
                    <FormattedMessage id="informations.address.add_address" />
                </Button>
                {openCreateAddressDialog && (
                    <AddressFormDialog open={openCreateAddressDialog} handleCloseDialog={handleCloseDialog} />
                )}
            </LoaderWrapper>
        </div>
    );
};

export default MyAddresses;
