import { FormattedMessage } from 'react-intl';
import { useQuery } from '@tanstack/react-query';

import { PageHeader } from '@components/commons';
import IconAlert from '@components/Icons/IconAlert';
import LoaderWrapper from '@components/Loader/LoaderWrapper';
import EmptyList from '@components/Page/EmptyList';
import ErrorOnFetch from '@components/Page/ErrorOnFetch';
import Pagination from '@components/Pagination/Pagination';
import WishlistsTabs from '@components/Tabs/WishlistsTabs';
import { useAppContext } from '@context/AppContext';
import { useCountry } from '@context/CountryContext';
import useCurrency from '@hooks/useCurrency';
import { usePagination } from '@hooks/usePagination';
import apiOms from '@utils/apiOms';
import type { BRANDS } from '@utils/brand';
import { DEFAULT_CURRENCYCODE, DEFAULT_SITECODE } from '@utils/common';
import { getContentRange } from '@utils/fetch';
import AlertListItem from './AlertListItem';
import { ALERTS_KEY, ITEMS_PER_PAGE } from './config';

interface GetAlerts {
    brandCode: BRANDS;
    currentPage: number;
    itemsPerPage: number;
    currencyCode: string;
    countryCode: string;
    siteCode: string;
}
const getAlerts = ({ brandCode, countryCode, currencyCode, currentPage, itemsPerPage, siteCode }: GetAlerts) => ({
    queryKey: [ALERTS_KEY, `${currentPage}-${itemsPerPage}`],
    queryFn: () =>
        apiOms.omsAuthenticated.getAlertsAuthenticated({
            brandCode,
            countryCode,
            currencyCode,
            page: currentPage,
            itemsPerPage,
            siteCode,
        }),
    keepPreviousData: true,
});

const AlertsPage = () => {
    const {
        brandCode,
        site: { code },
    } = useAppContext();
    const { currentPage, setCurrentPage } = usePagination();
    const { country } = useCountry();
    const currency = useCurrency();

    const {
        data: alertsResponse,
        isSuccess,
        isError,
        isInitialLoading,
    } = useQuery(
        getAlerts({
            brandCode: brandCode,
            countryCode: country.countryISO!,
            currencyCode: currency?.code || DEFAULT_CURRENCYCODE,
            currentPage,
            itemsPerPage: ITEMS_PER_PAGE,
            siteCode: code || DEFAULT_SITECODE,
        })
    );

    const contentRange = alertsResponse && getContentRange(alertsResponse);
    const alerts = alertsResponse?.data || [];

    return (
        <>
            <WishlistsTabs />
            <PageHeader title="alerts.title">
                <FormattedMessage id="alerts.title" />
            </PageHeader>
            <LoaderWrapper isLoading={isInitialLoading}>
                {isSuccess && alerts.length > 0 ? (
                    <div className="grid grid-cols-2 gap-[1.6rem] desktop:grid-cols-3">
                        {alerts.map((alert, i) => (
                            <AlertListItem alert={alert} index={i} key={alert.id} />
                        ))}
                    </div>
                ) : (
                    <EmptyList
                        icon={<IconAlert className="h-8" />}
                        message={<FormattedMessage id="alerts.no_alerts" />}
                    />
                )}
                {isError && <ErrorOnFetch />}
                {alerts.length > 0 ? (
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        contentRange={contentRange}
                        resultsByPage={ITEMS_PER_PAGE}
                    />
                ) : null}
            </LoaderWrapper>
        </>
    );
};

export default AlertsPage;
