import { defineMessages, FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { BoxWithTimeline } from '@components/Box/BoxWithTimeline';
import Button from '@components/Button/Button';
import ReturnOrderButton from '@components/Button/ReturnOrderButton';
import LinkButton from '@components/Link/LinkButton';
import Separator from '@components/Separator';
import HorizontalTimeline from '@components/Timeline/HorizontalTimeline';
import useConfirm from '@hooks/useConfirm';
import { useUrl } from '@hooks/useUrl';
import apiOms from '@utils/apiOms';
import { DEFAULT_IMG_PLACEHOLDER } from '@utils/common';
import { DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH } from '@utils/image';
import { getCustomerOrderTrackingStatus, orderTimelineStatuses } from '@utils/order';
import { shippingModeMessages } from './OrderDetails';
import type { OrderRead } from 'types/apiOms';

const orderTrackingMessages = defineMessages({
    in_preparation: { id: 'orders.order.detail.tracking.in_preparation' },
    validated: { id: 'orders.order.detail.tracking.validated' },
    shipped: { id: 'orders.order.detail.tracking.shipped' },
    delivered: { id: 'orders.order.detail.tracking.delivered' },
    delivered_to_customer: { id: 'orders.order.detail.tracking.delivered_to_customer' },
    canceled: { id: 'orders.order.detail.tracking.canceled' },
});

interface OrdersListItemProps {
    className?: string;
    order: OrderRead;
}

const OrdersListItem = ({ className, order }: OrdersListItemProps) => {
    const currentTrackingStatus = getCustomerOrderTrackingStatus(order?.status) || order?.status || 'canceled';
    const { routes } = useUrl();
    const queryClient = useQueryClient();
    const cancelOrder = useMutation(() => apiOms.omsAuthenticated.cancelOrder(order.number!), {
        onSettled: () => {
            queryClient.invalidateQueries(['orders']);
        },
    });
    const intl = useIntl();
    const confirmCancelOrder = useConfirm(
        intl.formatMessage({ id: 'orders.order.action.cancel_order.confirm' }),
        cancelOrder.mutate
    );
    const orderDetailUrl = `${routes.orders}/detail/${order.number}`;
    const shippingModeTrad = shippingModeMessages[order?.shipping?.shippingModeType!]?.id;
    const shouldUseReturnPoshmark = order.isPoshmarkResellable;

    return (
        <>
            <BoxWithTimeline
                className={className}
                logo={
                    <Link to={orderDetailUrl}>
                        <img
                            src={
                                order?.products
                                    ? order?.products[0]?.imageUrl || DEFAULT_IMG_PLACEHOLDER
                                    : DEFAULT_IMG_PLACEHOLDER
                            }
                            width={DEFAULT_IMAGE_WIDTH}
                            height={DEFAULT_IMAGE_HEIGHT}
                            className="h-full bg-gray-200 object-cover"
                            alt={order?.products ? order?.products[0]?.label : ''}
                        />
                    </Link>
                }
                horizontalTimeline={
                    <HorizontalTimeline
                        className="col-[2_/_4_span] mt-8 self-end mobile:mt-2 tablet:col-span-full tablet:mt-12"
                        displayedTrackingStatus={
                            order.shipping?.shippingModeType === 'store_delivery'
                                ? orderTimelineStatuses
                                : orderTimelineStatuses.filter(tlStatus => tlStatus !== 'delivered_to_customer')
                        }
                        currentTrackingStatus={currentTrackingStatus}
                        statusMessages={orderTrackingMessages}
                    />
                }
                buttons={
                    <>
                        <LinkButton className="m-0 w-full px-4" color="primary" to={orderDetailUrl}>
                            <FormattedMessage id="orders.order.detail" />
                        </LinkButton>
                        {order?.shipping?.shippingTrackingLink && order.status === 'shipped' && (
                            <LinkButton
                                color="secondary"
                                className="m-0 w-full px-4"
                                href={order?.shipping?.shippingTrackingLink}
                                target="_blank"
                            >
                                <FormattedMessage id="orders.order.track" />
                            </LinkButton>
                        )}
                        {shouldUseReturnPoshmark && (
                            <LinkButton color="secondary" className="m-0 w-full px-4" to={orderDetailUrl}>
                                <FormattedMessage id="orders.order.order_detail_poshmark" />
                            </LinkButton>
                        )}
                        {!shouldUseReturnPoshmark &&
                            (order.status === 'delivered_to_customer' ||
                                (order.status === 'delivered' &&
                                    order.shipping?.shippingModeType !== 'store_delivery')) &&
                            order.isReturnable && (
                                <ReturnOrderButton
                                    className="m-0 w-full px-4"
                                    color="secondary"
                                    orderId={order.number!}
                                />
                            )}
                        {order.isCancelable && (
                            <Button
                                fullWidth
                                color="secondary"
                                className="m-0 w-full px-4"
                                onClick={confirmCancelOrder}
                                isLoading={cancelOrder.isLoading}
                            >
                                <FormattedMessage id="orders.order.detail.action.cancel_order" />
                            </Button>
                        )}
                    </>
                }
            >
                <div className="flex">
                    <div className="flex grow flex-col gap-4 font-account-heading text-base uppercase">
                        <div className="flex mobile:flex-col desktop:items-center">
                            <Link
                                to={orderDetailUrl}
                                className="font-account-heading font-medium text-black desktop:mr-3"
                            >
                                <FormattedMessage id={'orders.order.order_id'} values={{ number: order.number }} />
                            </Link>
                        </div>
                        <div className="-mt-5 font-medium">
                            {order.localeTotalDisplayPrice || order.totalDisplayPrice}
                        </div>
                        {shippingModeTrad ? (
                            <FormattedMessage id={shippingModeTrad} />
                        ) : (
                            order.shipping?.shippingModeType
                        )}
                    </div>
                    <div className="text-right font-account-subheading uppercase">
                        <div>
                            <FormattedDate value={order.createdAt} day="numeric" month="long" year="numeric" />
                        </div>
                        <div>
                            <FormattedTime value={order.createdAt} hour="numeric" minute="numeric" />
                        </div>
                        <div>
                            <FormattedMessage
                                id="orders.order.article"
                                values={{ number: order.totalProductQuantity }}
                            />
                        </div>
                    </div>
                </div>
            </BoxWithTimeline>
            <Separator mobileOnly />
        </>
    );
};

export default OrdersListItem;
