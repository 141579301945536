import { useAppContext } from '@context/AppContext';
import { DEFAULT_SITECODE } from '@utils/common';
import type { Urls } from './utils';
import {
    getCartUrl,
    getFaqUrls,
    getKeycloakUrl,
    getPrivacyPolicyUrl,
    getReturnUrl,
    getRoutes,
    getSearchPageUrl,
} from './utils';
import type { Site } from 'types';

const getUrls = (frontUrl: string, site: Site, siteSegment: string, locale: string): Urls => {
    const siteCode = site.code || DEFAULT_SITECODE;
    const basePath = process.env.REACT_APP_BASE_PATH || '';
    const baseUrl = `${frontUrl}/${siteSegment}`;
    const accountBaseUrl = `/${siteSegment}`;

    return {
        account: `${accountBaseUrl}${basePath}`,
        ecommerce: baseUrl,
        ecommerceRoot: frontUrl,
        cart: getCartUrl(baseUrl, siteCode),
        search: getSearchPageUrl(baseUrl, siteCode, locale),
        faq: getFaqUrls(baseUrl, locale),
        contact: `${baseUrl}/contact`,
        return: getReturnUrl({ baseUrl, siteCode, locale }),
        keycloak: {
            email: `${getKeycloakUrl()}/auth/realms/customer/account`,
            password: `${getKeycloakUrl()}/auth/realms/customer/account/password`,
        },
        privacyPolicy: getPrivacyPolicyUrl(baseUrl, siteCode, locale),
    };
};

export const useUrl = () => {
    const { frontUrl, locale, site, siteSegment } = useAppContext();
    const urls = getUrls(frontUrl, site, siteSegment, locale);
    const routes = getRoutes(site.code, locale);

    return {
        urls,
        routes,
    };
};
