import fileDownload from 'js-file-download';
import { defineMessages, FormattedMessage, type MessageDescriptor, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';

import ToggledBlockAddress from '@components/Address/ToggledBlockAddress';
import StatusInfo from '@components/Box/StatusBox';
import Button from '@components/Button/Button';
import CancelOrderButton from '@components/Button/CancelOrderButton';
import ReturnPoshmark from '@components/Button/ReturnPoshmark';
import { PageHeader, SecondTitle, SubTitle } from '@components/commons';
import IconChevron from '@components/Icons/IconChevron';
import IconContact from '@components/Icons/IconContact';
import LinkButton from '@components/Link/LinkButton';
import Separator from '@components/Separator';
import VerticalTimeline from '@components/Timeline/VerticalTimeline';
import { useUrl } from '@hooks/useUrl';
import apiOms from '@utils/apiOms';
import { DEFAULT_IMG_PLACEHOLDER } from '@utils/common';
import { getCustomerOrderTrackingStatus, orderTimelineStatuses } from '@utils/order';
import { ORDER_KEY } from './config';
import EcardProperties from './EcardProperties';
import OrderCustomisation from './OrderCustomisation';
import trackingOptions from './trackingOptions';

export const shippingModeMessages = defineMessages({
    electronic: { id: 'orders.order.detail.shipping_mode.electronic' },
    globale: { id: 'orders.order.detail.shipping_mode.globale' },
    hand_delivery: { id: 'orders.order.detail.shipping_mode.hand_delivery' },
    home_delivery: { id: 'orders.order.detail.shipping_mode.home_delivery' },
    last_minute_shopping: { id: 'orders.order.detail.shipping_mode.last_minute_shopping' },
    pick_up_point: { id: 'orders.order.detail.shipping_mode.pick_up_point' },
    store_delivery: { id: 'orders.order.detail.shipping_mode.store_delivery' },
});

const getOrderDetail = (reference: string) => ({
    queryKey: [ORDER_KEY, `${reference}`],
    queryFn: () => apiOms.omsAuthenticated.getUserOrderAuthenticated(reference),
    keepPreviousData: true,
});

const OrderDetails = () => {
    const { orderId = 'testId' } = useParams();
    const intl = useIntl();
    const { routes, urls } = useUrl();

    const { data, isSuccess } = useQuery(getOrderDetail(orderId));
    const order = data?.data;
    const shouldUseReturnPoshmark = order ? order.isPoshmarkResellable : false;

    const { isLoading: isLoadingOrderInvoice, mutateAsync: getOrderInvoice } = useMutation(
        ['orderInvoice', order?.number],
        (reference: string) => apiOms.omsAuthenticated.getInvoice(reference),
        {
            onSuccess: async response => {
                const blob = await response.blob();
                try {
                    fileDownload(blob, `BL-${order?.number}.pdf`);
                } catch (error) {
                    console.error(error);
                }
            },
        }
    );

    const shippingModeTrad = shippingModeMessages[order?.shipping?.shippingModeType!]?.id;
    const currentCustomerTrackingStatus = getCustomerOrderTrackingStatus(order?.status) || order?.status || 'canceled';

    if (isSuccess && order) {
        return (
            <>
                <section className="container mb-20 flex w-full flex-col items-center gap-[1.6rem] mobile:mb-12">
                    <Link
                        className="mb-[1.6rem] flex items-center self-start text-left text-sm text-gray-medium desktop:hidden"
                        to={routes.orders}
                    >
                        <IconChevron className="mr-3 rotate-180" height={10} />
                        <FormattedMessage id="header.back.orders" />
                    </Link>
                    <PageHeader
                        className="mb-0 text-5xl mobile:mb-4 mobile:text-3xl"
                        title="orders.order.detail.head.title"
                        titleValues={{ number: orderId }}
                    >
                        <FormattedMessage id="orders.order.detail.head.title" values={{ number: orderId }} />
                    </PageHeader>

                    <div className="mb-10 flex flex-col items-center font-account-subheading text-gray-medium">
                        <p className="pb-4 uppercase">
                            <FormattedMessage
                                id="orders.order.detail.head.ordered_date"
                                values={{
                                    date: intl.formatDate(new Date(order.createdAt!), {
                                        dateStyle: 'short',
                                        timeStyle: 'short',
                                    }),
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id={
                                    `orders.order.detail.tracking.${currentCustomerTrackingStatus}` as MessageDescriptor['id']
                                }
                            />
                        </p>
                        <p>
                            {shippingModeTrad ? (
                                <FormattedMessage id={shippingModeTrad} />
                            ) : (
                                order.shipping?.shippingModeType
                            )}
                            {order.shipping?.shippingModeName && (
                                <>
                                    {' ('}
                                    {order.shipping?.shippingModeName}
                                    {')'}
                                </>
                            )}
                            {order.shipping?.shippingModeType !== 'store_delivery' &&
                                order.shipping?.shippingTrackingCode && (
                                    <>
                                        {' - '}
                                        <FormattedMessage id="orders.order.detail.head.tracking" />{' '}
                                        {order.shipping.shippingTrackingLink ? (
                                            <a
                                                href={order.shipping.shippingTrackingLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="underline"
                                            >
                                                {order.shipping.shippingTrackingCode}
                                            </a>
                                        ) : (
                                            order.shipping.shippingTrackingCode
                                        )}
                                    </>
                                )}
                        </p>
                    </div>

                    <div className="w-full text-center text-base desktop:flex desktop:justify-center desktop:gap-6">
                        {order.addresses?.shipping && (
                            <div className="mobile:mb-[1.6rem] desktop:w-1/3">
                                <ToggledBlockAddress
                                    label={
                                        <>
                                            <FormattedMessage id="orders.order.detail.head.delivery_place" /> :
                                            <span>
                                                {order.addresses?.shipping?.name &&
                                                    `“${order.addresses.shipping.name}”`}
                                            </span>
                                        </>
                                    }
                                    address={order.addresses.shipping}
                                />
                            </div>
                        )}
                        {order.addresses?.billing && (
                            <div className="desktop:w-1/3">
                                <ToggledBlockAddress
                                    label={
                                        <>
                                            <FormattedMessage id="orders.order.detail.head.billing_address" /> :
                                            <span>
                                                {order.addresses?.billing?.name && `“${order.addresses.billing.name}”`}
                                            </span>
                                        </>
                                    }
                                    address={order.addresses.billing}
                                />
                            </div>
                        )}
                    </div>

                    <div className="mt-10 grid auto-cols-fr auto-rows-max gap-8 mobile:mt-6 mobile:w-full mobile:gap-4 desktop:grid-flow-col">
                        {order.shipping?.shippingModeType !== 'store_delivery' &&
                            order.shipping?.shippingTrackingLink &&
                            order.status === 'shipped' && (
                                <LinkButton
                                    color="secondary"
                                    className="flex h-full w-full items-center justify-center"
                                    href={order.shipping?.shippingTrackingLink}
                                    target="_blank"
                                >
                                    <FormattedMessage id="orders.order.track" />
                                </LinkButton>
                            )}
                        {!!order.invoiceLink && (
                            <Button
                                color="secondary"
                                className="flex h-full w-full shrink-0 items-center justify-center"
                                onClick={() => getOrderInvoice(order.number!)}
                                isLoading={isLoadingOrderInvoice}
                            >
                                <FormattedMessage id="orders.order.detail.action.invoice" />
                            </Button>
                        )}
                        <LinkButton
                            className="flex h-full w-full shrink-0 items-center justify-center"
                            to={routes.contact}
                        >
                            <IconContact className="mr-4 h-10 w-10 shrink-0" />
                            <FormattedMessage id="orders.order.detail.action.contact_us" />
                        </LinkButton>
                        {order.isCancelable && (
                            <CancelOrderButton className="min-w-[200px]" uid={orderId} queryKey={ORDER_KEY} />
                        )}
                    </div>
                </section>

                {shouldUseReturnPoshmark ? (
                    <StatusInfo>
                        <SecondTitle
                            description={
                                <p>
                                    <FormattedMessage
                                        id="orders.order.detail.poshmark_description"
                                        values={{ br: <br />, i: chunks => <i>{chunks}</i> }}
                                    />
                                </p>
                            }
                            className="text-black"
                        >
                            <FormattedMessage id="orders.order.detail.poshmark_title" />
                        </SecondTitle>
                    </StatusInfo>
                ) : (
                    <Separator />
                )}

                <section className="mt-12 grid gap-16 mobile:mt-4 desktop:mt-16 desktop:grid-cols-2">
                    <div>
                        <SubTitle className="mb-8 mt-6">
                            <FormattedMessage
                                id="orders.order.detail.body.count_articles"
                                values={{ number: order.totalProductQuantity }}
                            />
                        </SubTitle>
                        <div className="mb-8 flex flex-col gap-6 text-base">
                            {order.products &&
                                order.products.map(product => (
                                    <>
                                        <div className="flex flex-row gap-6" key={product.ean}>
                                            <div className="w-[130px] shrink-0">
                                                {product.productUrl ? (
                                                    <a
                                                        href={`${urls.ecommerceRoot}${product.productUrl}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img
                                                            className="w-full"
                                                            src={product.imageUrl || DEFAULT_IMG_PLACEHOLDER}
                                                            alt={product.label}
                                                        />
                                                    </a>
                                                ) : (
                                                    <img
                                                        className="w-full"
                                                        src={product.imageUrl || DEFAULT_IMG_PLACEHOLDER}
                                                        alt={product.label}
                                                    />
                                                )}
                                            </div>
                                            <div className="flex flex-col justify-center">
                                                <span className="mb-4 font-account-heading text-3xl font-medium uppercase">
                                                    {product.productUrl ? (
                                                        <a
                                                            href={`${urls.ecommerceRoot}${product.productUrl}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {product.label}
                                                        </a>
                                                    ) : (
                                                        product.label
                                                    )}
                                                </span>
                                                <span className="mb-4 font-account-heading text-lg font-medium uppercase text-gray-medium">
                                                    {product.localeTotalDisplayPrice || product.totalDisplayPrice}
                                                </span>
                                                {product.ecardProperty ? (
                                                    <EcardProperties ecardProperty={product.ecardProperty} />
                                                ) : (
                                                    <>
                                                        {product.colorLabel && (
                                                            <span className="text-gray-medium ">
                                                                {product.colorLabel}
                                                            </span>
                                                        )}
                                                        {product.sizeLabel && (
                                                            <span className="text-gray-medium">
                                                                <FormattedMessage
                                                                    id="orders.order.detail.body.size"
                                                                    values={{ size: product.sizeLabel }}
                                                                />
                                                            </span>
                                                        )}
                                                        <span className="text-gray-medium">
                                                            <FormattedMessage
                                                                id="orders.order.detail.body.quantity"
                                                                values={{ quantity: product.quantity }}
                                                            />
                                                        </span>
                                                        <OrderCustomisation product={product} />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {shouldUseReturnPoshmark && !product.gift && (
                                            <ReturnPoshmark
                                                orderNumber={order.number!}
                                                lineItemUuid={product.orderItemProductId!}
                                            />
                                        )}
                                    </>
                                ))}
                        </div>
                        <div className="ml-auto flex flex-col gap-1 font-account-heading text-lg font-medium uppercase desktop:max-w-[50%]">
                            {(order.localeSubTotalDisplayPrice || order.subTotalDisplayPrice) && (
                                <span className="flex justify-between text-gray-light">
                                    <span>
                                        <FormattedMessage id="orders.order.detail.body.subtotal" />
                                    </span>
                                    <span>{order.localeSubTotalDisplayPrice || order.subTotalDisplayPrice}</span>
                                </span>
                            )}
                            {(order.localeShippingDisplayPrice || order.shippingDisplayPrice) && (
                                <span className="flex justify-between font-medium uppercase text-gray-light">
                                    <span>
                                        <FormattedMessage id="orders.order.detail.body.shipping" />
                                    </span>
                                    <span>{order.localeShippingDisplayPrice || order.shippingDisplayPrice}</span>
                                </span>
                            )}
                            {(order.localeDiscountDisplayPrice || order.discountDisplayPrice) && (
                                <span className="flex justify-between font-medium uppercase text-gray-light">
                                    <span>
                                        <FormattedMessage id="orders.order.detail.body.discount" />
                                    </span>
                                    <span>{order.localeDiscountDisplayPrice || order.discountDisplayPrice}</span>
                                </span>
                            )}
                            {(order.localeTotalDisplayPrice || order.totalDisplayPrice) && (
                                <span className="mt-6 flex justify-between font-medium uppercase text-gray-medium">
                                    <span>
                                        <FormattedMessage id="orders.order.detail.body.total" />{' '}
                                        <span className="text-sm font-normal">
                                            <FormattedMessage id="orders.order.detail.body.taxes" />
                                        </span>
                                    </span>
                                    <span>{order.localeTotalDisplayPrice || order.totalDisplayPrice}</span>
                                </span>
                            )}
                        </div>
                    </div>
                    <div>
                        <VerticalTimeline
                            orderType="order"
                            uid={orderId}
                            timelineStatuses={
                                order.shipping?.shippingModeType === 'store_delivery'
                                    ? orderTimelineStatuses
                                    : orderTimelineStatuses.filter(tlStatus => tlStatus !== 'delivered_to_customer')
                            }
                            trackingOptions={trackingOptions}
                            currentCustomerTrackingStatus={currentCustomerTrackingStatus}
                            isCancelable={order.isCancelable}
                            isReturnable={order.isReturnable}
                            shippingModeType={order.shipping?.shippingModeType}
                        />
                    </div>
                </section>
            </>
        );
    }
};

export default OrderDetails;
