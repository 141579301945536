import { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dialog } from '@sezane/front-components';
import { useQuery } from '@tanstack/react-query';

import IconWishlist from '@components/Icons/IconWishlist';
import LoaderWrapper from '@components/Loader/LoaderWrapper';
import EmptyList from '@components/Page/EmptyList';
import ErrorOnFetch from '@components/Page/ErrorOnFetch';
import Separator from '@components/Separator';
import WishlistsTabs from '@components/Tabs/WishlistsTabs';
import { useCountry } from '@context/CountryContext';
import useCurrency from '@hooks/useCurrency';
import { usePagination } from '@hooks/usePagination';
import { DEFAULT_CURRENCYCODE, DEFAULT_IMG_PLACEHOLDER, DEFAULT_SITECODE } from '@utils/common';
import { getContentRange } from '@utils/fetch';
import { PageHeader } from '../../components/commons';
import Pagination from '../../components/Pagination/Pagination';
import { useAppContext } from '../../context/AppContext';
import apiOms from '../../utils/apiOms';
import type { BRANDS } from '../../utils/brand';
import { ITEMS_PER_PAGE, WISHLIST_KEY } from './config';
import WishlistProductItem from './WishlistProductItem';
import type { OMS } from 'types';

interface GetWishlist {
    brandCode: BRANDS;
    countryCode: string;
    siteCode: string;
    currencyCode: string;
    currentPage: number;
    itemsPerPage: number;
    lastOnesAvailable?: boolean;
}
const getWishlist = ({ brandCode, siteCode, countryCode, currencyCode, currentPage, itemsPerPage }: GetWishlist) => {
    return {
        queryKey: [WISHLIST_KEY, `${currentPage}-${itemsPerPage}`],
        queryFn: () =>
            apiOms.omsAuthenticated.getWishlistItemsAuthenticated({
                brandCode,
                siteCode,
                countryCode,
                currencyCode,
                page: currentPage,
                itemsPerPage,
            }),
        keepPreviousData: true,
    };
};

const WishlistsPage = () => {
    const {
        brandCode,
        site: { code },
    } = useAppContext();
    const { currentPage, setCurrentPage } = usePagination();
    const { country } = useCountry();
    const currency = useCurrency();
    const [productAlert, setProductAlert] = useState<OMS.WishlistAndAlertItem>();

    const {
        data: wishlistsResponse,
        isSuccess,
        isInitialLoading,
        isError,
    } = useQuery(
        getWishlist({
            brandCode,
            siteCode: code || DEFAULT_SITECODE,
            countryCode: country.countryISO!,
            currencyCode: currency?.code || DEFAULT_CURRENCYCODE,
            currentPage,
            itemsPerPage: ITEMS_PER_PAGE,
        })
    );

    const contentRange = wishlistsResponse && getContentRange(wishlistsResponse);
    const wishlists = wishlistsResponse?.data || [];

    return (
        <>
            <WishlistsTabs />
            <PageHeader title="wishlists.title">
                <FormattedMessage id="wishlists.title" />
            </PageHeader>
            <LoaderWrapper isLoading={isInitialLoading}>
                {isSuccess && wishlists.length > 0 ? (
                    <div className="grid gap-[1.6rem] desktop:grid-cols-2">
                        {wishlists.map((wishlistItem, i) => (
                            <Fragment key={wishlistItem.id}>
                                <WishlistProductItem
                                    product={wishlistItem}
                                    index={i}
                                    setProductAlert={setProductAlert}
                                />
                                {i < wishlists.length - 1 && <Separator mobileOnly />}
                            </Fragment>
                        ))}
                    </div>
                ) : (
                    <EmptyList
                        icon={<IconWishlist className="h-8" />}
                        message={<FormattedMessage id="wishlists.no_wishlists" />}
                    />
                )}
                {isError && <ErrorOnFetch />}
                {wishlists.length > 0 ? (
                    <Pagination
                        contentRange={contentRange}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        resultsByPage={ITEMS_PER_PAGE}
                    />
                ) : null}
            </LoaderWrapper>
            {/* Confirm create Alert */}
            <Dialog open={!!productAlert} onClose={() => setProductAlert(undefined)} panelClassName="p-0 flex">
                <img
                    className="w-[27rem]"
                    src={productAlert?.imageUrl || DEFAULT_IMG_PLACEHOLDER}
                    alt={[productAlert?.name, productAlert?.color].join(' ')}
                />
                <div className="flex flex-col justify-center gap-6 p-14">
                    <h2 className="font-account-heading text-6xl font-bold uppercase leading-[5rem]">
                        <FormattedMessage id="wishlists.create_alert.title" />
                    </h2>
                    <p className="font-account-body">
                        <FormattedMessage id="wishlists.create_alert.description" />
                    </p>
                </div>
            </Dialog>
        </>
    );
};

export default WishlistsPage;
