import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { addToast, yupAddressValidation } from '@sezane/front-components';
import { useMutation } from '@tanstack/react-query';

import Button from '@components/Button/Button';
import { PageHeader } from '@components/commons';
import { useUrl } from '@hooks/useUrl';
import { formatAddressForOMSApi, normalizeAddressForDHL } from '@utils/address';
import apiOms from '@utils/apiOms';
import { checkReturnDhl } from '@utils/order';
import DhlConfirmationPage from './DhlConfirmationPage';
import ReturnNav from './ReturnNav';
import ReturnRecap from './ReturnRecap';
import StepConfirm from './StepConfirm';
import StepDhlSlot from './StepDhlSlot';
import StepOrder from './StepOrder';
import StepProducts from './StepProducts';
import StepRefund from './StepRefund';
import { type FormValues, getCreateReturnSteps, getStepsConfiguration, mapCreateValues } from './utils';
import type { OMS } from 'types';

defineMessages({
    returnReason: { id: 'return.validation.missing_reason' },
});

const validation = yup.object().shape({
    address: yup.object().when(['returnMode'], {
        is: (returnMode: OMS.ReturnMode) => !!returnMode && checkReturnDhl(returnMode),
        then: () =>
            yup.object().when(['manualAddress'], {
                is: true,
                then: () => yupAddressValidation({ manualAddress: true, isShippingAddress: true }),
                otherwise: () => yupAddressValidation({ manualAddress: false, isShippingAddress: true }),
            }),
    }),
    pickupDate: yup.object().when(['returnMode'], {
        is: (returnMode: OMS.ReturnMode) => !!returnMode && checkReturnDhl(returnMode),
        then: () => yup.object().required(),
    }),
    pickupSlot: yup.object().when(['returnMode'], {
        is: (returnMode: OMS.ReturnMode) => !!returnMode && checkReturnDhl(returnMode),
        then: () => yup.object().required(),
    }),
    timezone: yup.object().when(['withTimezone'], {
        is: true,
        then: () => yup.object().required(),
    }),
});

const ReturnCreatePage = () => {
    const { routes } = useUrl();
    const navigate = useNavigate();

    const methods = useForm<FormValues>({
        resolver: yupResolver(validation),
    });
    const formValues = methods.watch();

    const [currentStep, setCurrentStep] = useState(0);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [dhlConfirmedReturn, setDhlConfirmedReturn] = useState<OMS.UserOrderReturn>();

    const isDhl = !!formValues.returnMode && checkReturnDhl(formValues.returnMode);

    const { mutateAsync: checkDhlAddress } = useMutation(['checkDhlAddress'], (address: OMS.Address) => {
        return apiOms.omsAuthenticated.checkAddressForDhlPickupAuthenticated({
            ...formatAddressForOMSApi(normalizeAddressForDHL(address)),
            timezone: formValues.timezone?.value,
        });
    });
    const { mutateAsync: createReturn, isLoading: isLoadingCreateReturn } = useMutation(
        ['createOrderReturnAuthenticated'],
        (orderReturn: OMS.OrderReturnWrite) => apiOms.omsAuthenticated.createOrderReturnAuthenticated(orderReturn),
        {
            onError: () => {
                addToast(<FormattedMessage id="orderReturns.creation.error" />, 'warning');
            },
        }
    );

    const createReturnSteps = getCreateReturnSteps(isDhl);
    const stepsConfiguration = getStepsConfiguration(formValues, methods);
    const stepConfiguration = stepsConfiguration[createReturnSteps[currentStep]];

    const scrollToStep = (step: number) => {
        if (step === 0) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
            const stepId = createReturnSteps[step];
            const stepEl = document.getElementById(stepId);
            if (stepEl) {
                // TODO: fix scroll position on mobile (because of sticky header + nav)
                stepEl.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    const goToStep = (step: number, withScroll?: boolean) => {
        if (step !== currentStep) {
            setCurrentStep(step);
            methods.clearErrors();

            // reset form values
            if (step < 1) {
                methods.setValue('products', undefined);
            }
            if (step < 2) {
                methods.setValue('refundOption', undefined);
            }
            if (step < 3) {
                methods.setValue('address', undefined);
                methods.setValue('returnMode', undefined);
                methods.setValue('timezone', undefined);
                methods.setValue('withTimezone', undefined);
            }
            if (step < 4) {
                methods.setValue('pickupDate', undefined);
                methods.setValue('pickupSlot', undefined);
            }

            if (withScroll) {
                scrollToStep(step);
            }
        }
    };

    const submitStep = async () => {
        methods.clearErrors();

        if (stepConfiguration.checkErrors) {
            if (isDhl && currentStep === 3) {
                await stepConfiguration.checkErrors(async () => {
                    setIsLoadingSubmit(true);
                    return await checkDhlAddress(
                        formValues.address
                            ? {
                                  ...formValues.address,
                                  countryCode: formValues.address?.countryCode?.split('-')[0] || '',
                                  state:
                                      formValues.address?.countryCode?.split('-')[1] ||
                                      formValues.address?.state ||
                                      null,
                              }
                            : ({} as OMS.Address)
                    )
                        .catch(() => {
                            methods.setError('address', { type: 'invalid_dhl' });
                            addToast(<FormattedMessage id="validation.address.address.invalid_dhl" />, 'warning');
                        })
                        .finally(() => setIsLoadingSubmit(false));
                });
            } else {
                await stepConfiguration.checkErrors();
            }
        }

        if (!Object.keys(methods.formState.errors).length) {
            const hasDefect =
                !!formValues.products &&
                Object.values(formValues.products)?.some(({ reason }) => reason?.name === 'DEFECTIVE');

            if (formValues.order?.externalId) {
                // GlobalE redirect
                window.location.href = `${process.env.REACT_APP_GLOBALE_RETURN_URL}/?OrderID=${formValues.order.externalId}`;
            } else if (hasDefect) {
                navigate(routes.returnDefect, { state: { fromApp: true } });
            } else {
                goToStep(currentStep + 1);
            }
        }
    };

    const onSubmit = methods.handleSubmit(values => {
        createReturn(mapCreateValues(values)).then(response => {
            if (isDhl) {
                setDhlConfirmedReturn(response.data);
            } else {
                navigate(routes.returns, { state: { createReturnSuccess: true } });
            }
        });
    });

    return (
        <FormProvider {...methods}>
            <div className="relative desktop:grid desktop:grid-cols-12">
                {!dhlConfirmedReturn && (
                    <div className="sticky top-[70px] z-10 bg-white mobile:-mx-6 mobile:px-6 desktop:hidden">
                        <ReturnNav currentStep={currentStep} goToStep={goToStep} isDhl={isDhl} />
                    </div>
                )}
                <div className="relative mobile:pt-14 desktop:col-span-6 desktop:col-start-4">
                    {dhlConfirmedReturn ? (
                        <DhlConfirmationPage orderReturn={dhlConfirmedReturn} />
                    ) : (
                        <>
                            <PageHeader className="mb-12" title="return.title">
                                <FormattedMessage id="return.title" />
                            </PageHeader>

                            <StepOrder onChange={() => goToStep(0)} onInitOrderFromState={() => goToStep(1)} />
                            {currentStep > 0 && <StepProducts onChange={() => goToStep(1)} />}
                            {currentStep > 1 && <StepRefund onChange={() => goToStep(2)} />}
                            {currentStep > 2 && <StepConfirm onChange={() => goToStep(3)} />}
                            {isDhl && currentStep > 3 && <StepDhlSlot />}

                            <div className="sticky bottom-5 mt-32 text-center">
                                {currentStep < createReturnSteps.length - 1 ? (
                                    <Button
                                        className="uppercase"
                                        disabled={stepConfiguration.canSubmit && !stepConfiguration.canSubmit()}
                                        isLoading={isLoadingSubmit}
                                        onClick={submitStep}
                                    >
                                        <FormattedMessage id="return.next_step" />
                                    </Button>
                                ) : (
                                    <Button className="uppercase" isLoading={isLoadingCreateReturn} onClick={onSubmit}>
                                        <FormattedMessage id="return.confirm" />
                                    </Button>
                                )}
                            </div>
                        </>
                    )}
                </div>
                {!dhlConfirmedReturn && (
                    <aside className="col-span-2 col-start-11 mobile:hidden">
                        <div className="sticky top-5 text-base">
                            <ReturnNav currentStep={currentStep} goToStep={goToStep} isDhl={isDhl} />
                            <ReturnRecap />
                        </div>
                    </aside>
                )}
            </div>
        </FormProvider>
    );
};

export default ReturnCreatePage;
