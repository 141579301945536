import { defineMessages, useIntl } from 'react-intl';

import { useAppContext } from '@context/AppContext';
import { useUrl } from '@hooks/useUrl';
import type { Routes, Urls } from '@hooks/useUrl/utils';
import { BRANDS } from '@utils/brand';
import IconAlert from '../Icons/IconAlert';
import IconContact from '../Icons/IconContact';
import IconCredits from '../Icons/IconCredits';
import IconDeleteAccount from '../Icons/IconDeleteAccount';
import IconInfo from '../Icons/IconInfo';
import IconLogout from '../Icons/IconLogout';
import IconOrders from '../Icons/IconOrders';
import IconPayment from '../Icons/IconPayment';
import IconReturns from '../Icons/IconReturns';
import IconWishlist from '../Icons/IconWishlist';
import MenuItem from './MenuItem';
import './Menu.css';
import type { Site } from 'types';

export const menuHomeMessages = defineMessages({
    octobre: { id: 'menu.home.octobre' },
    sezane: { id: 'menu.home.sezane' },
});

export const menuMessages = defineMessages({
    reception: { id: 'menu.reception' },
    alerts: { id: 'menu.alerts' },
    care: { id: 'menu.care' },
    contact: { id: 'menu.contact' },
    credits_ecards: { id: 'menu.credits_ecards' },
    delete_account: { id: 'menu.delete_account' },
    informations: { id: 'menu.informations' },
    orders: { id: 'menu.orders' },
    payment: { id: 'menu.payment' },
    returns: { id: 'menu.returns' },
    wishlists: { id: 'menu.wishlists' },
});

export const getMenuData = (routes: Routes, urlEcommerce?: Urls['ecommerce']) => [
    // { id: 'home', link: '/', icon: <IconSezaneMoi className="w-full" /> },
    ...(urlEcommerce ? [{ id: 'reception', link: urlEcommerce }] : []), // mobile only
    {
        id: 'orders',
        link: routes.orders,
        icon: <IconOrders className="w-full" />,
        additionalMatchLinks: [`${routes.orders}/detail/:id`],
    },
    {
        id: 'returns',
        link: routes.returns,
        icon: <IconReturns className="w-full" />,
        additionalMatchLinks: [`${routes.returns}/detail/:id`],
    },
    { id: 'wishlists', link: routes.wishlists, icon: <IconWishlist className="w-full" /> },
    { id: 'alerts', link: routes.alerts, icon: <IconAlert className="w-full" /> },
    // { id: 'care', link: routes.care, icon: <IconCare /> },
    { id: 'informations', link: routes.info, icon: <IconInfo className="w-full" /> },
    { id: 'credits_ecards', link: routes.credits, icon: <IconCredits className="w-full" /> },
    { id: 'payment', link: routes.payment, icon: <IconPayment className="w-full" /> },
    { id: 'contact', link: routes.contact, icon: <IconContact className="w-full" /> },
    { id: 'delete_account', link: routes.deleteAccount, icon: <IconDeleteAccount className="w-full" /> },
];

export const logout = (brandCode: BRANDS, site: Site) => () => {
    if (brandCode === BRANDS.sezane) {
        window.location.href = `${process.env.REACT_APP_SEZANE_FRONT_URL}/${site.code}/disconnect`;
    } else {
        window.location.href = `${process.env.REACT_APP_OCTOBRE_FRONT_URL}/${site.code}/disconnect`;
    }
};

const Menu = () => {
    const { brandCode, site } = useAppContext();
    const { routes } = useUrl();
    const intl = useIntl();

    const menuData = getMenuData(routes);

    return (
        <nav className="menu sticky top-0 py-3.5 font-account-heading font-medium uppercase">
            <ul className="grid max-w-md gap-8">
                {menuData.map(({ id, link, icon, additionalMatchLinks }) => (
                    <MenuItem
                        key={id}
                        label={intl.formatMessage(id === 'home' ? menuHomeMessages[brandCode] : menuMessages[id])}
                        link={link}
                        icon={icon}
                        additionalMatchLinks={additionalMatchLinks}
                    />
                ))}
                <MenuItem
                    label={intl.formatMessage({ id: 'menu.logout' })}
                    icon={<IconLogout className="w-full" />}
                    onClick={logout(brandCode, site)}
                    className="mt-10"
                />
            </ul>
        </nav>
    );
};

export default Menu;
