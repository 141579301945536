import { FormattedMessage } from 'react-intl';

import LinkButton, { type LinkButtonProps } from '@components/Link/LinkButton';
import { useUrl } from '@hooks/useUrl';

interface ReturnOrderButtonProps extends LinkButtonProps {
    orderId?: string;
}

const ReturnOrderButton = ({ className, color, orderId }: ReturnOrderButtonProps) => {
    const { routes } = useUrl();

    return (
        <LinkButton color={color} className={className} to={routes.returnCreate} state={{ orderId }}>
            <FormattedMessage id="orders.order.detail.action.return" />
        </LinkButton>
    );
};

export default ReturnOrderButton;
