import type { OMS } from 'types';

export type AddressForm = Omit<OMS.UserAddress, 'address'> & {
    address: OMS.UserAddress['address'] & {
        searchStreetNumber?: boolean;
        streetNumber?: string;
        streetNumberPosition?: 'before' | 'after';
    };
};

export const formatAddressForOMSApi = (address: AddressForm['address']): OMS.UserAddress['address'] => {
    return {
        ...address,
        address:
            address?.streetNumber && address?.streetNumber !== '' && address?.streetNumberPosition
                ? address?.streetNumberPosition === 'before'
                    ? `${address?.streetNumber} ${address?.address}`
                    : `${address?.address} ${address?.streetNumber}`
                : address?.address,
        firstName: address?.firstName && address?.firstName?.trim(),
        lastName: address?.lastName && address?.lastName?.trim(),
        name: address?.name && address?.name?.trim(),
    };
};

const removeAccents = (prop: string) => prop.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

// Remove accents and dashes for DHL
export const normalizeAddressForDHL = (address: AddressForm['address']) => ({
    ...address,
    city: removeAccents(address.city).replace(/-/g, ' '),
});
