import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { initDatadog } from './utils/datadog';

import 'vite/modulepreload-polyfill'; // Use vite polyfill here as we serve html page from php backend

initDatadog();

function renderApp() {
    const container = document.getElementById('root');
    const root = createRoot(container!);

    root.render(
        <StrictMode>
            <App />
        </StrictMode>
    );
}

renderApp();
