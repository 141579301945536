import { FormattedMessage } from 'react-intl';
import { useQuery } from '@tanstack/react-query';

import AddressSimple from '@components/Address/AddressSimple';
import DisplayReturnedItem from '@components/Box/Returns/DisplayReturnedItem';
import ReturnInformations from '@components/Box/Returns/ReturnInformations';
import CancelOrderReturnButton from '@components/Button/CancelOrderReturnButton';
import { SecondTitle, Title } from '@components/commons';
import Loader from '@components/Loader/Loader';
import Separator from '@components/Separator';
import { useAppContext } from '@context/AppContext';
import { parseTimezoneDate } from '@pages/ReturnCreatePage/utils';
import apiOms from '@utils/apiOms';
import { checkReturnDhl } from '@utils/order';
import { type ReturnModeType } from '@utils/return';
import { AdditionalInformations } from './additionalInformations/AdditionalInformations';
import ReturnDetailsLayout from './Layout/ReturnDetailsLayout';
import ReturnSections from './returnSections/ReturnSections';
import type { OMS } from 'types';
import type { AdditionalInformation, UserOrderReturn } from 'types/apiOms';

type CurrentTrackingStatus = 'validated' | 'in_transit' | 'refunded' | 'canceled';

interface ReturnDetailsValidatedProps {
    orderReturn: UserOrderReturn;
    currentTrackingStatus: CurrentTrackingStatus;
}

const isAdditionalInformation = (additionalInformation: AdditionalInformation[]) =>
    additionalInformation.some(
        additionalInformation => additionalInformation.name === 'gift' || additionalInformation.name === 'klarna'
    );

const ReturnDetailsValidated = ({ orderReturn, currentTrackingStatus }: ReturnDetailsValidatedProps) => {
    const { locale } = useAppContext();

    const { isLoading: isLoadingReturnModes, data } = useQuery({
        queryKey: ['return-mode', orderReturn.uid],
        queryFn: () =>
            apiOms.omsAuthenticated.getEligibleOrderReturnModes(String(orderReturn.firstOrderReference!), { locale }),
    });
    const eligibleReturnModes = data?.data;

    // TODO: fix undefined timezones when we get the info in orderReturn from the API
    const pickupStartDate = orderReturn.pickupDateAndTime
        ? parseTimezoneDate(orderReturn.pickupDateAndTime, undefined)
        : undefined;
    const pickupEndDate = orderReturn.pickupClosingTime
        ? parseTimezoneDate(orderReturn.pickupClosingTime, undefined)
        : undefined;

    const isDHL =
        !!eligibleReturnModes?.returnModes?.some(checkReturnDhl) &&
        !!pickupStartDate &&
        !!pickupEndDate &&
        !!orderReturn.pickupAddress;

    return (
        <ReturnDetailsLayout
            orderReturnId={orderReturn.id}
            orderReturnModeType={orderReturn.returnMode as ReturnModeType}
            currentTrackingStatus={currentTrackingStatus}
        >
            <section className="large:col-span-2">
                <div className="flex w-full flex-col [counter-reset:section]">
                    {isDHL && orderReturn.pickupAddress && (
                        <div className="mb-12">
                            <p>
                                <FormattedMessage id="orderReturn.return.detail.dhl.appointment.description" />
                            </p>
                            <AddressSimple address={orderReturn.pickupAddress} className="text-sm font-bold" />
                        </div>
                    )}
                    <Title className="mb-8 text-6xl font-normal normal-case desktop:mb-12">
                        <FormattedMessage id="orderReturn.return.detail.howto" />
                    </Title>
                    <Separator mobileOnly className="mx-0 my-12" />
                    {isLoadingReturnModes ? (
                        <Loader />
                    ) : (
                        <ReturnSections
                            orderReference={orderReturn.firstOrderReference?.toString()}
                            countryISO={orderReturn?.addresses?.shipping?.address?.countryCode!}
                            eligibleReturnModes={eligibleReturnModes}
                            pickupDateStart={orderReturn.pickupDateAndTime}
                            pickupDateEnd={orderReturn.pickupClosingTime}
                            pickupAddress={orderReturn.pickupAddress}
                            hasReturnLabel={orderReturn.hasReturnLabel}
                            isDhl={isDHL}
                        />
                    )}
                </div>
                {/* Additional Informations */}
                {orderReturn.additionalInformation && isAdditionalInformation(orderReturn.additionalInformation) && (
                    <>
                        <SecondTitle className="pt-20 text-6xl font-normal normal-case">
                            <FormattedMessage id="orderReturn.return.detail.general_informations" />
                        </SecondTitle>
                        <AdditionalInformations
                            additionalInformations={orderReturn.additionalInformation}
                            refundOption={orderReturn.refund_mode as OMS.OrderReturn['refundOption']}
                        />
                    </>
                )}
            </section>
            {/* Sidebar */}
            <section className="flex flex-col gap-[1.6rem]">
                <SecondTitle className="text-6xl font-normal normal-case">
                    <FormattedMessage
                        id="orderReturn.return.detail.products.title"
                        values={{ itemCount: orderReturn.totalReturnItems }}
                    />
                </SecondTitle>
                {orderReturn.returnedItems &&
                    orderReturn.returnedItems.map((product, index) => (
                        <DisplayReturnedItem product={product} key={`${product.orderLineItem?.uid}-${index}`} />
                    ))}
                <Separator className="mx-0 mb-6 mt-8" />
                <ReturnInformations
                    orderReference={orderReturn.firstOrderReference}
                    refundMode={orderReturn.refund_mode}
                />
                {orderReturn.uid && orderReturn.status === 'pending' && (
                    <CancelOrderReturnButton uid={orderReturn.uid} className="mt-8" />
                )}
            </section>
        </ReturnDetailsLayout>
    );
};

export default ReturnDetailsValidated;
