import { FormattedDate, FormattedMessage } from 'react-intl';

import type { OMS } from 'types';

interface EcardPropertiesProps {
    ecardProperty: OMS.EcardProperty;
}

const EcardProperties = ({ ecardProperty }: EcardPropertiesProps) => {
    return (
        <>
            <span className="text-gray-medium">
                <FormattedMessage id="orders.order.detail.body.ecard.sender" /> {ecardProperty.senderFirstname}{' '}
                {ecardProperty.senderLastname}
            </span>
            <span className="text-gray-medium">
                <FormattedMessage id="orders.order.detail.body.ecard.receiver" /> {ecardProperty.receiverEmail}
            </span>
            <span className="text-gray-medium">
                <FormattedMessage id="orders.order.detail.body.ecard.send_at" />{' '}
                <FormattedDate day="2-digit" month="2-digit" year="2-digit" value={ecardProperty.sendAt} />
            </span>
            {ecardProperty.message && (
                <span className="mt-4 font-medium">
                    <FormattedMessage id="orders.order.detail.body.ecard.message" />
                    {' “'}
                    {ecardProperty.message}
                    {'”'}
                </span>
            )}
        </>
    );
};

export default EcardProperties;
