import { initDatadog as initDatadogComponents } from '@sezane/front-components';

export const initDatadog = () => {
    if (process.env.NODE_ENV !== 'production') {
        return;
    }

    initDatadogComponents({
        clientToken: 'pub87bef53b3a843648f6030060cde237b2',
        applicationId: 'eb5c4e90-11f9-4c5b-9e71-231ac9b699de',
        site: 'datadoghq.eu',
        service: 'account',
        env: process.env.REACT_APP_ENV,
        version: process.env.COMMIT_HASH,
        sessionSampleRate: 50,
        sessionReplaySampleRate: 5,
        traceSampleRate: 100,
        traceContextInjection: 'all',
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        trackSessionAcrossSubdomains: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: ['https://api.sezane.com', 'https://api.octobre-editions.com'],
    });
};
